//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable global-require */
import store from '@/store/index';
export default {
  data: function data() {
    return {
      downImg: require('@/assets/images/pages/coming-soon.svg')
    };
  },
  computed: {
    imgUrl: function imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg');
        return this.downImg;
      }

      return this.downImg;
    }
  }
};